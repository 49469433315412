/* src/styles/tailwind.css */

* {

  margin: 0;
  padding: 0;
  box-sizing: border-box;

  scroll-behavior: smooth;
}

.scroll-to-top-btn {
  opacity: 0;
  transform: scale(0.5);
  /* Usamos 'transform' en lugar de 'scale' directamente */
  transition: opacity 0.3s ease, transform 0.3s ease;
  /* Animaciones suaves para la opacidad y escala */
}